import { Component, OnInit } from '@angular/core';
import * as data from '../product/items/products.json';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {

  products: any = (data as any).default;
  product: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const productId = this.route.snapshot.queryParamMap.get('productIndex');
    this.product = this.products[productId];
    console.log(this.product)
  }

}
