import { Component, OnInit } from '@angular/core';
import * as data from './items/products.json';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  products: any = (data as any).default;
  breakpoint: number = 2;
  constructor(private observer: BreakpointObserver) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.breakpoint = 1;
      } else {
        this.breakpoint = 2;
      }
    });
  }

}
