import { Component, OnInit } from '@angular/core';
import { particles } from '../../../assets/particlesConfig/particlesConfig.json';

@Component({
  selector: 'app-particles',
  templateUrl: './particles.component.html',
  styleUrls: ['./particles.component.css']
})
export class ParticlesComponent implements OnInit {

  showIndex = true;
  myStyle: object = {};
  myParams: object = {};
  paramss: object = {};
  width = 100;
  height = 100;

  GetStarted() {
    this.showIndex = false;
  }

  ngOnInit(): void {
    this.myStyle = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: '#05081f',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };


    this.paramss = JSON.parse(JSON.stringify(particles));
    this.myParams = {
      particles: this.paramss
    };
    //console.log(this.myParams);
  }

}
