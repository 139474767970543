<mat-toolbar>
    <button mat-icon-button *ngIf="sidenav.mode == 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
            menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
            close
        </mat-icon>
    </button>
    <h2> DEEPXP.io</h2>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav">  
                        <app-bar></app-bar>
    </mat-sidenav>
  
    <mat-sidenav-content>
        
        <app-particles></app-particles>
        <router-outlet></router-outlet>
            
        
    </mat-sidenav-content>
</mat-sidenav-container>