<mat-grid-list [cols]="breakpoint" rowHeight="2:1">
    <mat-grid-tile *ngFor="let product of products, let i = index">
        <a href="/single-product?productIndex={{i}}">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{product.title}}</mat-card-title>
                    <mat-card-subtitle>{{product.subtitle}}</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div fxLayout="column">
                        <div class="row">
                            <div class="text-col" fxLayout="column" fxFlex="60">
                                <p>
                                    {{product.description}}
                                </p>
                            </div>
                            <div fxLayout="column" fxFlex="36"><img class="img-thumbnail" mat-card-image
                                    src={{product.media[0]}} alt="Photo of a Shiba Inu"></div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button>LIKE</button>
                    <button mat-button>SHARE</button>
                </mat-card-actions>
            </mat-card>
        </a>
    </mat-grid-tile>
</mat-grid-list>