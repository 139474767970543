<div class="container-flex" style="margin: 50px;">
    <div class="row row-prod">
        <div class="col-md-7">

            <app-description [title]=product.title [description]=product.description></app-description>

        </div>
        <div class="col-md-5">

            <app-media [images]=product.media></app-media>

        </div>
    </div>
    <div class="row row-prod">
        <div class="col">

            <app-links [links]=product.links></app-links>

        </div>
    </div>
</div>