import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ProductComponent } from './pages/product/product.component';
import { SingleProductComponent } from './pages/single-product/single-product.component';
const routes: Routes = [
  {
  path: 'about',
  component: AboutComponent,
},

{
  path: 'product',
  component: ProductComponent,
},

{
  path: '',
  component: WelcomeComponent,
},

{
  path: 'single-product',
  component: SingleProductComponent,
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
