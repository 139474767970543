
    <mat-card>

        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <ng-template ngFor let-i="index" let-image [ngForOf]="images">
                <div *ngIf="i==0; else notFirst" class="carousel-item active">
                  <img class="d-block w-100" src={{image}}>
                </div>
                <ng-template #notFirst>
                  <div class="carousel-item">
                    <img class="d-block w-100" src={{image}}>
                  </div>
                </ng-template>
              </ng-template>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </mat-card>